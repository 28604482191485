import React, { useCallback, useEffect, useState } from "react";
import { useCampaign } from "../../context/CampaignContext";
import { makeNextGameUseCase } from "../../team-game/main/helper/make-next-game-use-case";
import * as S from "./style";

interface INextMatch {
  name: string;
  logo: string;
  league?: { name: string };
  date?: string;
}

const nextGame = makeNextGameUseCase()

function NextMatch(): React.ReactElement {
  const [nextMatch, setNextMatch] = useState<INextMatch>();
  const { campaignData } = useCampaign();

  const findNextMatch = useCallback(async () => {
    const response = await nextGame.handle({ teamId: 754 })
    setNextMatch({
      name: response.title,
      logo: response.image,
    });
  }, [])

  useEffect(() => {
    findNextMatch()
  }, [findNextMatch]);

  return (
    <S.NextMatchContainer>
      <S.NextMatchTitle>
        <span>
          {nextMatch?.date}
        </span>
        <span>
          Próximo jogo
        </span>
        <span>
          {nextMatch?.date}
        </span>
      </S.NextMatchTitle>
      <S.Players>
        <S.NextMatchPlayer>
          <img src={campaignData.teamShield} alt="escudo-casa" className="home" />
          <span>{campaignData.teamName}</span>
        </S.NextMatchPlayer>
        <span>X</span>
        {nextMatch ? (
          <>
            <S.NextMatchPlayer>
              <img src={nextMatch.logo} alt="escudo-opposing-team" />
              <span>{nextMatch.name}</span>
            </S.NextMatchPlayer>
          </>
        ) : (
          <>
          <S.NextMatchPlayer>
            <img src="favicon.ico" alt="escudo-opposing-team" />
            <span>Em breve</span>
          </S.NextMatchPlayer>
        </>
        )}
      </S.Players>
    </S.NextMatchContainer>
  );
}

export default NextMatch;
