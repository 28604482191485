import { BrowserRouter, Route, Routes } from "react-router-dom";

import { useEffect } from "react";
import "./App.css";
import CampaignProvider from "./context/CampaignContext";
import { ContactFormProvider } from "./context/ContactFormDataContext";
import {
    useGlobalState
} from "./context/GlobalStateContext";
import { SearchFormProvider } from "./context/SearchFormDataContext";
import Beneficios from "./pages/Beneficios";
import Contato from "./pages/Contato";
import Home from "./pages/Home";
import Parceiros from "./pages/Parceiros";
import { getPricesCdt } from "./services/prices-cartao-todos.service";

function App() {
  const { setCdtPrices } = useGlobalState();
  useEffect(() => {
    async function PriceCdt() {
      const response = await getPricesCdt();
      setCdtPrices(response);
      console.log("cdtprices", response);
    }
    PriceCdt();
  }, []);
  return (
    <CampaignProvider>
        <SearchFormProvider>
            <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/parceiros" element={<Parceiros />} />
                <Route path="/beneficios" element={<Beneficios />} />
                <Route
                path="/contato"
                element={
                    <ContactFormProvider>
                    <Contato />
                    </ContactFormProvider>
                }
                />
            </Routes>
            </BrowserRouter>
        </SearchFormProvider>
    </CampaignProvider>
  );
}

export default App;
